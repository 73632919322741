$breakpoints: (
  "phone": 500px,
  "tablet": 900px,
  "desktop": 1024px,
  "widescreen": 1440px,
);

/* media-min mixin for minimum-width breakpoints */
@mixin media-min($_key) {
  @media screen and (min-width: map-get($breakpoints, $_key)) {
    @content; // Directly include content without `&`
  }
}

/* media-max mixin for maximum-width breakpoints */
@mixin media-max($_key) {
  @media screen and (max-width: calc(map-get($breakpoints, $_key) - 1px)) {
    @content; // Directly include content without `&`
  }
}

/* media-between mixin for range-based media queries */
@mixin media-between($_keymin, $_keymax) {
  @media screen and (min-width: map-get($breakpoints, $_keymin)) and (max-width: calc(map-get($breakpoints, $_keymax) - 1px)) {
    @content; // Directly include content without `&`
  }
}

/* media mixin for targeting one specific breakpoint */
@mixin media($_key) {
  $breakpoint-keys: map-keys($breakpoints);
  $next-index: index($breakpoint-keys, $_key) + 1;

  // Check if there is a next breakpoint or if it's the last one
  @if $next-index <= length($breakpoint-keys) {
    $next-breakpoint: map-get($breakpoints, nth($breakpoint-keys, $next-index));

    @media screen and (min-width: map-get($breakpoints, $_key)) and (max-width: calc($next-breakpoint - 1px)) {
      @content; // Directly include content without `&`
    }
  } @else {
    // If no next breakpoint, use only min-width
    @media screen and (min-width: map-get($breakpoints, $_key)) {
      @content; // Directly include content without `&`
    }
  }
}
