@import '../../styles/variables.scss';

:global {
  .minimap-container-container {
    width: 100%;
    height: 274px;
  }

  p {
    margin-bottom: 0!important; // Override markdown padding
  }
}

.PanelChronology {
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;

  .headingBlocks {
    @include media-max("tablet") {
      gap: 18px!important;
    }
  }

  .colorblock {
    width: 20px;
    height: 10px;
    border-radius: 3px;
  }
}
