@import "./variables.scss";

/**
 * Override some global values set by React Boostrap with _reboot.scss
 */

body,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-family: var(--main-font);
  font-size: 14px;
  font-weight: normal;
  line-height: 22px;
  color: var(--text-color);
  text-align: left;
  background-color: var(--color-light);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(transparent, 0);
}

/* usefull for sidebar and chronoview */
body {
  width: 100vw;
  width: 100dvw;
  overflow: hidden;
  background-color: var(--color-light);
  color: var(--text-color);
}

h1 {
  font-family: var(--main-font-semi-bold);
  font-size: 30px;
  line-height: 36px;
  margin-bottom: 28px;
}

h2 {
  font-family: var(--main-font-semi-bold);
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 12px;
  margin-top: 34px;
}

h3,
h4,
h5,
h6 {
  font-family: var(--main-font-semi-bold);
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 10px;
  margin-top: 24px;
}

/**
 * Links
 */

a,
a:visited {
  color: var(--link-color);
  text-decoration-color: var(--link-color);
  text-decoration: none;
  border-bottom: 1px dotted currentColor;
  &:hover {
    color: var(--color-primary);
    border-bottom-color: currentColor;
    border-bottom: 1px solid currentColor;
  }
  &[target="_blank"] {
    &::after {
      content: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSIxMiIgZmlsbD0ibm9uZSI+PHBhdGggZmlsbD0iIzAwMCIgc3Ryb2tlPSIjMDAwIiBzdHJva2Utd2lkdGg9Ii41IiBkPSJNOS4yMzEgOS42ODJjMCAuMjMtLjE4Ni40MTUtLjQxNS40MTVIMi4zMThhLjQxNS40MTUgMCAwIDEtLjQxNi0uNDE1VjMuMTg0YzAtLjIzLjE4Ni0uNDE1LjQxNi0uNDE1aDMuODk5di0uOTAzaC0zLjlBMS4zMiAxLjMyIDAgMCAwIDEgMy4xODR2Ni40OThDMSAxMC40MSAxLjU5IDExIDIuMzE4IDExaDYuNDk4YTEuMzIgMS4zMiAwIDAgMCAxLjMxNy0xLjMxOFY1Ljc4M2gtLjkwMnYzLjlaIi8+PHBhdGggZmlsbD0iIzAwMCIgc3Ryb2tlPSIjMDAwIiBzdHJva2Utd2lkdGg9Ii41IiBkPSJNMTAuNTQ5IDFINy41MTZ2LjkwM2gxLjk0Mkw1LjAzMSA2LjMzbC42MzkuNjQgNC40MjctNC40M3YxLjk0NEgxMVYxLjQ1YS40NS40NSAwIDAgMC0uNDUxLS40NVoiLz48L3N2Zz4=");
      margin-left: 8px;
    }
    &:hover {
      &::after {
        content: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSIxMiIgZmlsbD0ibm9uZSI+PHBhdGggZmlsbD0iI2JmYTY2YSIgc3Ryb2tlPSIjYmZhNjZhIiBzdHJva2Utd2lkdGg9Ii41IiBkPSJNOS4yMzEgOS42ODJjMCAuMjMtLjE4Ni40MTUtLjQxNS40MTVIMi4zMThhLjQxNS40MTUgMCAwIDEtLjQxNi0uNDE1VjMuMTg0YzAtLjIzLjE4Ni0uNDE1LjQxNi0uNDE1aDMuODk5di0uOTAzaC0zLjlBMS4zMiAxLjMyIDAgMCAwIDEgMy4xODR2Ni40OThDMSAxMC40MSAxLjU5IDExIDIuMzE4IDExaDYuNDk4YTEuMzIgMS4zMiAwIDAgMCAxLjMxNy0xLjMxOFY1Ljc4M2gtLjkwMnYzLjlaIi8+PHBhdGggZmlsbD0iI2JmYTY2YSIgc3Ryb2tlPSIjYmZhNjZhIiBzdHJva2Utd2lkdGg9Ii41IiBkPSJNMTAuNTQ5IDFINy41MTZ2LjkwM2gxLjk0Mkw1LjAzMSA2LjMzbC42MzkuNjQgNC40MjctNC40M3YxLjk0NEgxMVYxLjQ1YS40NS40NSAwIDAgMC0uNDUxLS40NVoiLz48L3N2Zz4=");
      }
    }
    &.no-icon {
      &::after {
        content: none;
        margin: 0;
      }
      &:hover {
        &::after {
          content: none;
        }
      }
    }
  }
}

ol,
ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  ol ol,
  ul ul,
  ol ul,
  ul ol {
    margin: 0;
    padding: 0;
  }
}

li {
  display: flex;
  &::before {
  content: "•";
    margin-right: 10px;
  }
}

blockquote,
dl,
dd,
p {
  margin: 0;
  padding: 0;
}

b,
strong {
  font-family: var(--main-font-semi-bold);
  font-weight: normal;
}

/**
 *   Buttons
 */
.btn {
  border-radius: 5px;
  color: var(--text-color);
  font-size: 13px;
  font-family: var(--main-font-semi-bold);
  font-weight: normal;
  padding: 6.5px 12px;
  &.big {
    text-transform: uppercase;
    height: 50px;
  }
}

.btn-primary {
  border-color: var(--color-primary);
  background-color: var(--color-primary);
  color: var(--text-color);
  &:hover {
    background: var(--color-lightgold);
    color: var(--text-color);
    border-color: var(--color-lightgold);
  }
}

.btn-secondary {
  font-size: 14px;
  border-color: var(--color-secondary);
  background-color: var(--color-secondary);
  color: var(--text-color);
  font-family: var(--main-font);
  &:hover {
    background-color: var(--color-lightgold);
    border-color: var(--color-lightgold);
  }
  &.not-selected {
    &:hover {
      color: var(--text-color);
    }
  }
  &.selected {
    background-color: var(--color-primary);
    border-color: var(--color-primary);
    &:hover {
      background-color: var(--color-darkgold);
      border-color: var(--color-darkgold);
      color: var(--text-color);
    }
  }
}

.btn-light {
  border-color: var(--color-black);
  background-color: var(--color-light);
  &:hover {
    background-color: var(--color-lightgold);
    border-color: var(--color-black);
  }
}

/**
 *   Scrollbar
 */
* {
  scrollbar-width: auto;
  scrollbar-color: var(--color-primary) var(--color-secondary);
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track {
  background: var(--color-secondary);
}

*::-webkit-scrollbar-thumb {
  background-color: var(--color-primary);
  border-radius: 0;
  border: none;
}

/**
 * Form label
 */

.form-label {
  font-size: 12px;
  font-family: var(--main-font-semi-bold);
  line-height: 22px;
  margin: 0;
  margin-bottom: 3px;
  padding: 0;
  color: var(--color-grey);
  text-transform: uppercase;
}

/**
 * Form select
 */

.form-select {
  background: none;
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23BFA66A%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 1.5em center;
  background-size: 0.8em 0.8em;
  border: none;
  color: inherit;
  option {
    background: var(--color-dark);
    color: var(--color-primary);
  }
}

/**
 * Form check
 */

 .form-check {
  display: inline-flex;
  align-items: center;
  column-gap: 9px;
  margin: 0;
  padding: 0;
  label {
    &:hover {
      cursor: pointer;
    }
  }
  input[type=checkbox],
  input[type=radio] {
    margin: 0;
    padding: 0;
    &:hover {
      cursor: pointer;
    }
    &:checked {
      background-color: var(--color-primary);
      border: none;
    }
  }
  input[type=radio] {
  }
  input[type=checkbox] {
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e")!important;
    background-color: var(--color-secondary);
    width: 55px;
    height: 25px;
  }
  &.form-switch {
    width: 100%;
    label {
      flex: 1 1 auto;
      order: -1;
    }
    input {
      order: 1;
    }
  }
 }

/**
 * Input type text / number
**/
input[type=text], input[type=number] {
  font-size: 14px;
  line-height: 17px;
  padding: 8px;
  border-color: none;
}

/**
 * Dropdown
**/

.dropdown {
  margin: 0;
  .dropdown-toggle {
    background-color: var(--color-grey);
    border: solid 1px var(--color-grey);
    height: 39px;
    border-radius: 5px;
    margin: 3px 0;
  }
  .dropdown-toggle::after {
    display: block;
    content: "";
    border: none;
    position: absolute;
    right: 0;
    top: 14px;
    height: 16px;
    width: 16px;
    background: none;
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23BFA66A%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-size: 0.8em 0.8em;
    color: black;
  }

  .dropdown-item {
    background-color: var(--color-grey);
    border: solid 1px var(--color-grey);
    height: 39px;
    border-radius: 5px;
    margin: 3px 0;
  }
  .dropdown-menu {
    margin: 0;
    border: none;
    padding: 0;
  }
}

/**
 * popover
 */

.popover {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  padding: 10px 14px 10px 14px;
  row-gap: 7px;
  a {
    color: var(--color-white);
    text-decoration-color: currentColor;
    &:after {
      content: none;
    }
    &:hover {
      &:after {
        content: none;
      }
    }
  }
  .popover-header {
    padding: 0;
    margin: 0;
    font-weight: normal;
    font-family: var(--main-font-bold);
    border: none;
  }
  .popover-body {
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-weight: normal;
  }
}

/**
 * Modal
*/

.modal-dialog {
  max-width: min(calc(100vw - 40px), 800px);
  margin: 0 auto;
}

/**
 * Swiper
 */

.swiper-button-disabled {
  opacity: 0.2;
  pointer-events: none;
}
