@import "../../styles/variables.scss";
@import "../../styles/responsive.scss";

.map-container-container {
  flex-grow: 1;
  display: flex;
  position: relative;
  width: 100%;

  .map-container {
    flex-grow: 1;
    display: flex;
    width: 100%;
  }

  .maplibregl-control-container {
    .maplibregl-ctrl-bottom-right {
      margin: 0px 10px 65px 0px;
      .maplibregl-ctrl-group {
        position: relative;
        box-shadow: none;
        background: none;
        border: none;
        margin: 0;
        z-index: 1;
        & > button {
          background-color: var(--color-dark);
          border-radius: 2px;
          box-shadow: none;
          border: none;
          outline: none;
          margin-bottom: 1px;
          box-shadow: none;
          &:hover {
            background-color: var(--color-primary);
          }
        }
        button.MapButtonLayers {
          border-radius: 2px;
          &.active {
            background-color: var(--color-primary);
          }
        }

        .button {
          margin-top: 1px;
          border-radius: 2px;
          &.active {
            background-color: var(--color-primary) !important;
          }
          svg {
            margin: -3px -3px -3px -3px;
            width: 35px;
            height: 35px;
          }
        }
      }
      /* Override scales */
      .maplibregl-ctrl-scale {
        margin: 0 42px 0 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        border-color: var(--color-light);
        font-size: 9px;
        color: var(--color-light);
        height: 15px;
        &:nth-child(even) {
          height: 13px;
          border: 2px solid white;
          transform: translateY(-28px);
          border-top: none;
        }
        &:nth-child(odd) {
          height: 13px;
          border: 2px solid white;
          transform: translateY(-30px);
          border-bottom: none;
        }
      }
    }
  }

  /* override attributions position */
  .mapboxgl-ctrl-bottom-left,
  .maplibregl-ctrl-bottom-left {
    width: 100%;
    text-align: center;
  }
  .mapboxgl-ctrl.mapboxgl-ctrl-attrib,
  .maplibregl-ctrl.maplibregl-ctrl-attrib {
    display: inline-block;
    background: none;
    float: none;
    margin-bottom: 7px; // Align with chronology startDate / endDate
    .maplibregl-ctrl-attrib-inner {
      font-weight: normal;
      color: var(--color-light);
      a {
        font-weight: normal;
        color: var(--color-light);
        text-decoration: none;
        border-bottom: none;
        &:after {
          display: none;
        }
      }
    }
  }

  .arkeolayers {
    position: absolute;
    overflow: hidden;
    right: 0;
    top: 50%;
    opacity: 1;
    border-radius: 2px;
    transform: translate(-31px, -50%);
    z-index: -1;
    .arkeolayers-list {
      display: flex;
      button {
        background-color: transparent;
        padding: 0;
        border: none;
        border-radius: 2px;
        outline: none;
      }
      button.selected {
        border: solid 1px #fff;
      }
    }
    transition: opacity 0.2s ease-in, transform 0.3s ease-in;
  }

  .maplibregl-popup-anchor-right .maplibregl-popup-tip {
    border-left-color: var(--color-backgrey);
    transform: scaleY(0.5);
  }
  .maplibregl-popup-anchor-left .maplibregl-popup-tip {
    border-right-color: var(--color-backgrey);
    transform: scaleY(0.5);
  }
  .maplibregl-popup-anchor-top .maplibregl-popup-tip {
    border-bottom-color: var(--color-backgrey);
    transform: scaleX(0.5);
  }
  .maplibregl-popup-anchor-bottom .maplibregl-popup-tip {
    border-top-color:  var(--color-backgrey);
    transform: scaleX(0.5);
  }

  .maplibregl-popup-content {
    background-color: var(--color-backgrey);
    color: var(--color-light);
    font-weight: 700;
    font-size: 14px;
    font-family: $font-family-sans-serif;
    padding: 10px 15px;
    border-radius: 5px;
  }

  .maplibregl-popup-tip {
    border: 14px solid transparent;
  }

  .arkeolayers.closed {
    opacity: 0;
    pointer-events: none;
    transform: translate(100%, -50%);
  }

  @include media-max("tablet") {
    .maplibregl-ctrl-zoom-in, .maplibregl-ctrl-zoom-out {
      display: none;
    }
  }

}
