@import "../../styles/variables.scss";

.closeButton {
  background: var(--color-grey);
  display: grid;
  width: 73px;
  grid-template-columns: 1fr 1fr;
  border: 1px solid var(--color-grey);
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--color-light);
    cursor: pointer;
    transition: color 0.3s;
    &.disabled {
      color: var(--color-secondary);
      pointer-events: none;
      transition: color 0.3s;
    }
  }
}

.button {
  width: 100%;
  height: 72px;
  grid-column: 1 / 3;
}

.arrowLeft {
  width: 36px;
  height: 36px;
  border-top: 1px solid var(--color-grey);
  border-right: 1px solid var(--color-grey);
}

.arrowRight {
  border-top: 1px solid var(--color-grey);
  width: 36px;
  height: 36px;
}
