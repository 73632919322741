@import "./styles/variables.scss";

.App {
  min-height: 100vh; /* fallback for old browers */
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
