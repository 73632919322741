.markdown {
  p {
    margin-bottom: 1em;
    word-break: break-word;
    white-space: pre-wrap;
  }
  li {
    display: block;
  }

  ul {
    ul {
      li {
        margin-left: 18px;
      }
    }
  }
}
