@import '../../styles/variables.scss';

.FormSearchWhen {
  display: flex;
  flex-direction: column;
  row-gap: 25px;
  margin-top: 32px;
  .periodselector {
    display: flex;
    flex-direction: row;
    gap: 4px;
    input {
      background: var(--color-secondary);
    }
  }
  .FlatSelect {
    margin: 2px 0 2px 0;
  }
  input[type="number"] {
    margin: 2px 0 2px 0;
  }
}

.periodmenu-colorblock {
  width: 30px;
  height: 10px;
  border-radius: 3px;
  border: solid 1px white;
  display: inline-block;
  margin-left: 20px;
}
