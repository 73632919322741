@import "../../styles/variables.scss";
@import "../../styles/responsive.scss";

.componentHeader {
  position: absolute;
  top: 0;
  height: 140px;
  width: 100%;
  pointer-events: none;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.56) 0%,
    rgba(0, 0, 0, 0) 100%
  );

  a {
    display: block;
    font-size: var(--header-font-size);
    text-transform: uppercase;
    color: var(--color-light);
    font-weight: normal;
    font-family: var(--main-font-semi-bold);
    line-height: normal;
    font-size: 14px;
    text-decoration: none;
    border: none;
    cursor: pointer;
    &:hover {
      color: var(--color-primary);
      svg {
        color: var(--color-primary);
      }
    }
  }

  .visibleOnPhoneOnly {
    display: none;
  }
  .visibleOnDesktopOnly {
    display: flex;
  }

  header {
    display: flex;
    height: $headerHeight;
    align-items: center;
    justify-content: space-between;
    padding: 0 11px 0 31px;

    .logo {
      align-items: flex-start;
      justify-content: flex-start;
      align-content: flex-start;
      img {
        height: 35px;
      }
    }

    .linksAndIcons {
      display: flex;
      align-items: center;
      transform: translateY(-3px);

      .links {
        display: flex;
        justify-content: space-between;
        align-items: center;
        a {
          padding: 24px;
          transition: color 0.15s ease-out;
          &.active {
            color: var(--color-primary);
            transition: color 0.15s ease-in;
          }
        }
      }

      .icons {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-left: 12px;
        a {
          padding: 16px;
          pointer-events: all;
          transition: color 0.15s ease-out;
          &.active {
            color: var(--color-primary);
            transition: color 0.15s ease-in;
          }
        }
        .mobileSearch {
          transform: scale(0.5);
        }
      }
    }
  }
  a {
    pointer-events: auto;
  }
}

@include media-max("tablet") {
  .componentHeader {
    .visibleOnPhoneOnly {
      display: flex;
    }
    .visibleOnDesktopOnly {
      display: none !important;
    }
  }
}
