@import "../../styles/responsive.scss";

.WelcomeModal {
  color: black;
  .modal-header {
    padding: 60px 60px 15px 60px;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    border-bottom: none;
  }
  .modal-body {
    padding: 15px 60px 15px 60px;
  }
  .modal-footer {
    padding: 15px 60px 40px 60px;
    border-top: none;
    justify-content: center;
    button {
      margin: 0 13px;
      flex: 0 260px;
    }
  }
  .logos {
    margin-top: 40px;
  }
}

@include media-max("tablet") {
  .WelcomeModal {
    .modal-header {
      padding: 20px 20px 5px 20px;
    }
    .modal-body {
      padding: 5px 20px 20px 20px;
      .presentation {
        display: none;
      }
    }
    .modal-footer {
      padding: 5px 20px 20px 20px;
      flex-direction: column;
      align-items: stretch;
      button {
        margin: 5px 0;
        flex: 0 1 50px;
      }
    }
  }
}

.loading {
  display: flex;
  align-items: center;
  gap: 40px;
}

.dot-pulse {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--color-primary);
  color: var(--color-primary);
  box-shadow: 9999px 0 0 -5px;
  animation: dot-pulse 1.5s infinite linear;
  animation-delay: 0.25s;
  &::before, &::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: var(--color-primary);
    color: var(--color-primary);
  }
  &::before {
    box-shadow: 9984px 0 0 -5px;
    animation: dot-pulse-before 1.5s infinite linear;
    animation-delay: 0s;
  }
  &::after {
    box-shadow: 10014px 0 0 -5px;
    animation: dot-pulse-after 1.5s infinite linear;
    animation-delay: 0.5s;
  }
}

@keyframes dot-pulse-before {
  0% {
    box-shadow: 9984px 0 0 -5px;
  }
  30% {
    box-shadow: 9984px 0 0 2px;
  }
  60%, 100% {
    box-shadow: 9984px 0 0 -5px;
  }
}
@keyframes dot-pulse {
  0% {
    box-shadow: 9999px 0 0 -5px;
  }
  30% {
    box-shadow: 9999px 0 0 2px;
  }
  60%, 100% {
    box-shadow: 9999px 0 0 -5px;
  }
}
@keyframes dot-pulse-after {
  0% {
    box-shadow: 10014px 0 0 -5px;
  }
  30% {
    box-shadow: 10014px 0 0 2px;
  }
  60%, 100% {
    box-shadow: 10014px 0 0 -5px;
  }
}
