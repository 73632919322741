@import '../../styles/variables.scss';

.FormSearchWhat {
  display: flex;
  flex-direction: column;
  row-gap: 25px;
  margin-top: 32px;
  .FlatSelect {
    margin: 2px 0 2px 0;
  }
  .switchsContainer {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
  }
}
