@import "../../styles/variables.scss";
@import "../../styles/responsive.scss";

:global {
  .minimap-container-container {
    width: 100%;
    height: 274px;
  }
}

.SiteComponent {
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;

  .error {
    font-size: 24px;
    padding: 10px;
  }

  .colorblock {
    width: 20px;
    height: 10px;
    border-radius: 3px;
  }

  .headingBlocks {
    @include media-max("tablet") {
      gap: 18px!important;
    }
  }

  .characAccordion {
    & > div[class~="accordion-item"] {
      border: none;
      &.hideAppendices {
        .accordionContent {
          padding: 0;
          row-gap: 4px;
        }
      }
      & > h2 {
        display: flex;
        font-family: var(--main-font-bold);
        margin-top: 12px;
        & > button {
          display: grid;
          grid-template-columns: 1fr max-content min-content;
          color: currentColor;
          background: none;
          box-shadow: none;
          border-bottom: 1px solid currentColor;
          font-size: 18px;
          line-height: 19px;
          padding: 12px 0px;
          justify-content: space-between;
          & > a {
            font-size: 14px;
            font-family: var(--main-font-semi-bold);
            border: none;
            text-decoration: underline;
            transform: translateX(-20px);
            &:hover {
              border: none;
            }
          }
        }
      }
    }
    .accordionContent {
      display: grid;
      grid-template-rows: min-content min-content min-content;
      row-gap: 21px;
      padding-bottom: 36px; // Space betwwen sub characs
      .subCharacName {
        font-size: 16px;
        line-height: 19px;
        margin-top: 12px;
        font-family: var(--main-font-semi-bold);
      }
      .knowledgeType {
        font-weight: normal;
        padding-left: 18px;
        & > div {
          margin-left: 2px;
        }
      }
      .contentHeader {
        position: relative;
        .exceptional {
          position: absolute;
          top: 12px;
          right: 26px;
          width: max-content;
          font-family: var(--main-font-bold);
          color: var(--color-primary);
          &:after {
            content: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNSIgZmlsbD0ibm9uZSI+PHBhdGggZmlsbD0iI0JGQTY2QSIgZD0iTTE1Ljk3MyA1Ljk0NWEuNTQ2LjU0NiAwIDAgMC0uNTItLjM2NkgxMC4zTDguNTIuMzYyQS41NDcuNTQ3IDAgMCAwIDggMGEuNTQ2LjU0NiAwIDAgMC0uNTIuMzYyTDUuNyA1LjU4SC41NDdhLjU0NS41NDUgMCAwIDAtLjUyMS4zNjYuNTE3LjUxNyAwIDAgMCAuMjA0LjU5TDQuMzk4IDkuMzlsLTEuNjggNC45MThhLjUxNy41MTcgMCAwIDAgLjE5Ni41OS41NTkuNTU5IDAgMCAwIC42NDMuMDA0TDggMTEuODU4bDQuNDQzIDMuMDQ0Yy4xOTMuMTMzLjQ1LjEzLjY0Mi0uMDA1YS41MTcuNTE3IDAgMCAwIC4xOTYtLjU4OUwxMS42IDkuMzlsNC4xNjktMi44NTVhLjUxNC41MTQgMCAwIDAgLjIwMy0uNTlaIi8+PC9zdmc+");
            position: absolute;
            top: 0;
            right: -24px;
          }
          @include media-max("tablet") {
            position: relative;
            transform: translate(44px, -6px);
          }
        }
      }
    }
  }

  .commentAccordion {
    button {
      background-color: var(--color-lightgrey) !important;
      border-color: var(--color-lightgrey) !important;
    }
    div[class~="accordion-body"] {
      padding-top: 0;
      background-color: var(--color-lightgrey) !important;
      border-color: var(--color-lightgrey) !important;
    }
  }

  .bibliographyAccordion {
    div[class~="accordion-body"] {
      padding-top: 0;
    }
  }

  .siteRanges {
    margin-top: 36px;
  }
}
