
@import "../../styles/variables.scss";

.container {
  position: relative;
  border-radius: 2px;
  background: var(--color-dark);
  .slidingLabel {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: var(--color-light);
    white-space: nowrap;
    top: 50%;
    right: 0;
    transform: translate(34px, -50%);
    opacity: 0;
    z-index: -1;
    transition: opacity 0.2s ease-in, transform 0.3s ease-in;
    background-color: var(--color-dark);
    padding: 3px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 3px;
  }
  &:hover {
    background-color: var(--color-primary);
    .slidingLabel {
      transform: translate(-34px, -50%);
      opacity: 1;
      transition: opacity 0.4s ease-out, transform 0.2s ease-out;
    }
  }
}
