@import "../../styles/variables.scss";

$headerHeight: 110px;

.button {
  background: none;
  border: none;
  &:disabled {
    img {
      filter: grayscale(100%) opacity(30%);
    }
  }
  img {
    width: 38px;
  }
}

.iframeContainer {
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100%;
  transition: transform 0.8s ease;
  transform: translateX(100%);
  background: var(--color-light);
  &.in.show {
    transform: translateX(0);
  }
}

.iframeHeader {
  display: block;
  padding-top: 34px;
  height: $headerHeight;
  color: #000;
  font-family: Inter;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-left: 40px;
  .siteName {
    max-width: 300px;
  }
}

.iframeContent {
  display: flex;
  height: calc(100% - #{$headerHeight});
}

iframe {
  transition: opacity 0.3s;
}
