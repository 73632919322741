@import "../../styles/variables.scss";

.slidableTabs {
  .tabContainer {
    display: grid;
    position: relative;
    grid-template-columns: calc(100% - 45px) 35px;
    grid-column-gap: 10px;
    justify-content: flex-start;
    &::before {
      position: absolute;
      content: "";
      border-bottom: 1px solid var(--color-grey);
      left: 0;
      bottom: 1px;
      width: 100%;
    }
    swiper-container {
      flex: 1 0 auto;
      align-items: baseline;
      justify-content: flex-start;
      margin: 0;
      swiper-slide {
        width: auto;
      }
    }
    .navigation {
      display: flex;
      flex: 0 0 auto;
      align-items: center;
      justify-content: space-between;
    }
    a {
      position: relative;
      font-size: 16px;
      font-family: var(--main-font-semi-bold);
      padding: 10px 18px 9px 18px;
      color: var(--color-grey);
      text-transform: uppercase;
      transition: none;
      &:hover {
        color: #000;
        border: none;
      }
      &::after {
        content: "";
        left: 20px;
        right: 20px;
        bottom: 0px;
        position: absolute;
        border-bottom: 4px solid transparent;
      }
      &.active {
        border-radius: 5px 5px 0 0;
        border: 1px solid var(--color-grey);
        border-bottom: 2px solid var(--color-light);
        color: var(--color-primary);
        &::after {
          border-bottom: 4px solid var(--color-primary);
        }
      }
    }
  }
  &.hideNavigationOnBigScreen {
    @include media-min("tablet") {
      .tabContainer {
        grid-template-columns: 100%;
      }
      .navigation {
        display: none;
      }
    }
  }
}

// data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMCIgaGVpZ2h0PSIxMiIgZmlsbD0ibm9uZSI+PHBhdGggZmlsbD0iIzAwMCIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNLjAxOSAxLjE0Qy0uMTQuNTA4LjcyOS0uMzAxIDEuNC4xMTNsOC4zMjggNS4xNTJjLjMzNS4xNzcuMzU1IDEuMDg1IDAgMS4yODJMMS40IDExLjY5OGMtLjY3MS40MTUtMS41NC0uMzk0LTEuMzgyLTEuMDI2VjEuMTRaIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiLz48L3N2Zz4=
