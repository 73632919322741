@import '../../styles/variables.scss';

.FormSearchOthers {
  display: flex;
  flex-direction: column;
  row-gap: 25px;
  margin-top: 32px;
  .TextualSearch {
    position: relative;
    input[type=text] {
      background-color: var(--color-secondary);
    }
    .icon {
      position: absolute;
      font-size: 24px;
      top: 6px;
      right: 10px;
      pointer-events: none;
    }
  }
  .FlatSelect {
    margin: 2px 0 2px 0;
  }
  .radioButtonsContainer {
    display: flex;
    flex-wrap: wrap;
    row-gap: 11px;
    column-gap: 21px;
  }
}
