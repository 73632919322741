@import "../../styles/responsive.scss";

.fundersLogos {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    justify-content: space-between;
    align-items: center;
    justify-items: center;
    img:nth-of-type(1) {
      justify-self: start;
    }
    @include media-max("tablet") {
      margin: 0 20px;
      grid-template-columns: repeat(3, 1fr);
      justify-content: start;
      transform: translateY(-12px);
      img {
        padding: 8px 0;
      }
      img:nth-of-type(1n) {
        justify-self: start;
      }
      img:nth-of-type(3n-1) {
        justify-self: center;
      }
      img:nth-of-type(3n) {
        justify-self: end;
      }
    }
    @include media-max("phone") {
      margin: 0 5px;
      grid-template-columns: repeat(2, 1fr);
      justify-content: center;
      img {
        justify-self: center!important;
      }
      img:nth-of-type(odd) {
        justify-self: start!important;
      }
      img:nth-of-type(even) {
        justify-self: end!important;
      }
    }
  }
