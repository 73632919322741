@import "../../styles/variables.scss";
@import "../../styles/responsive.scss";

/* theses variables are imported :
--sidePanelLeftSize: 480px;
--sidePanelRightSize: 960px;
*/

.SidePanel {
  position: fixed;
  top: $headerHeight; // height of header
  height: calc(100vh - $headerHeight); /* Fallback for old browsers */
  height: calc(100dvh - $headerHeight);
  background-color: var(--color-light);
  z-index: 3;
  transition: transform 0.3s;

  .popover {
    border: 1px solid red!important;
  }
}

.SidePanel.left {
  left: 0;
  right: auto;
  width: var(--sidePanelLeftSize);
  transform: translateX(-100%);
  .SidePanelHandle {
    left: var(--sidePanelLeftSize);
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
  }
}

.SidePanel.right {
  left: auto;
  right: 0;
  width: var(--sidePanelRightSize);
  transform: translateX(100%);
  .SidePanelHandle {
    left: -16px;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
  }
}

.SidePanel.show {
  .SidePanelHandle {
    svg {
      transform: rotate(180deg);
    }
  }
}

.SidePanel.left.show {
  transform: translateX(0);
}

.SidePanel.right.show {
  transform: translateX(0);
}

.SidePanelHandle {
  position: absolute;
  width: 16px;
  top: calc(50vh - 5em - 100px / 2);
  height: 100px;
  background-color: var(--color-dark);
  color: black;
  transition: top 0.3s;
  svg {
    transition: transform 1s;
    height: 16px;
    width: 16px;
  }

  // remove button styles
  border: none;
  margin: 0;
  padding: 0;
  border-radius: 0;
}

.SidePanelHandle:hover {
    background-color: var(--color-primary);
}

@include media-max("tablet") {
  .SidePanel.left,
  .SidePanel.right {
    display: none;
    position: absolute;
    left: 0 !important;
    right: 0 !important;
    top: 0!important;
    bottom: 0!important;
    width: auto;
    height: auto;
  }
  .SidePanel.left.show {
    display: block;
  }

  .SidePanel.right.show {
    display: block;
  }

  .SidePanelHandle {
    display: none;
  }
}

.popover-panel-text {
  font-size: 14px;
  padding: 0px;
}
