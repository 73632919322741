@import "../../styles/variables.scss";
@import "../../styles/responsive.scss";

.contentWithLabel {
  display: flex;
  column-gap: 4px;
  &.split {
    @include media-max("tablet") {
      flex-direction: column;
      margin-bottom: 12px;
      & > div:first-of-type {
        min-width: auto !important; // Override inline style
        max-width: 100% !important; // Override inline style
      }
    }
  }
  & > div:first-of-type {
    font-family: var(--main-font-bold);
    white-space: nowrap;
    white-space: wrap;
    flex: 0 0 auto;
    &.noChildren {
      max-width: 100%;
    }
  }
  & > div:last-of-type {
    font-family: var(--main-font);
    white-space: wrap;
  }
}

.align {
  display: flex;
  align-items: baseline;
  column-gap: 4px;
  &.smallFont {
    font-size: 12px;
    line-height: 18px;
  }
  &.split {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: 16px;
    @include media-max("tablet") {
      grid-template-columns: 1fr;
      gap: 0px;
    }
  }
}

.titleWithIcons {
  display: grid;
  grid-template-columns: 1fr min-content;
  & > div {
    display: flex;
    align-items: center;
    margin-top: -50px;
    gap: 12px;
  }
}

.simpleAccordion {
  .item {
    .header {
      margin: 0;
      button {
        background-color: var(--color-light);
        box-shadow: none;
        color: var(--text-color);
        font-size: 14px;
        font-weight: normal;
        font-family: var(--main-font-semi-bold);
        border: 1px solid var(--color-secondary);
        border-bottom-width: 0 !important;
        border-radius: 5px 5px 0 0 !important;
      }
    }
    & > div:not([class~="show"]) {
      .body {
        border: 1px solid var(--color-secondary);
        border-radius: 5px;
      }
    }
    & > div[class~="show"],
    div[class~="collapsing"] {
      .body {
        border: 1px solid var(--color-secondary);
        border-top: 0;
        border-radius: 0 0 5px 5px;
      }
    }
  }
  .item {
    &:has(button[class~="collapsed"]):not(:has(div[class~="collapsing"])) {
      button {
        border-bottom-width: 1px !important;
        border-radius: 5px !important;
      }
    }
  }
}

.sticker {
  background-color: var(--color-primary);
  color: var(--color-light);
  border-radius: 3px;
  text-align: center;
  line-height: 1.2rem;
  padding: 0px 4px;
}

.descriptionBlock {
  border-radius: 5px;
  background-color: var(--color-lightgrey);
  padding: 20px 16px;
  & > div:first-of-type {
    font-family: var(--main-font-bold);
    margin-bottom: 8px;
  }
}

.spacedContent {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
