@import "../../styles/variables.scss";

// $height: calc(10px * 4 + 1px * 10 + 20px);
$height: 140px;
$padding: 9px;

.ChronoviewContainer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: $height;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: $height;
  padding: 60px $padding $padding $padding;
  transition: padding 0.5s;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.56) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  pointer-events: none;
  &.sidepanel-left {
    padding-left: calc(var(--sidePanelLeftSize) + $padding);
  }
  &.sidepanel-right {
    padding-right: calc(var(--sidePanelRightSize) + $padding);
  }
  .Chronoview {
    pointer-events: all;
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    font-family: var(--main-font);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    transition: all 0.5s;

    &.hidding {
      transform: translateY(80px);
      & > .ChronoviewTitle {
        transform: translateY(-5px);
      }
    }

    .ChronoLine {
      display: flex;
      flex-direction: row;
    }

    .ChronoBloc {
      position: relative;
      display: flex;
      flex-direction: column;
    }

    .ChronoBloc.selected > .ColoredPeriod {
      background-image: url(../../../public/chronology-hachures.png);
    }

    .ColoredPeriod {
      border-radius: 3px;
      margin: 1px;
      height: 10px;
    }

    .ChronoviewTitle {
      position: absolute;
      left: 2px;
      top: -22px;
      font-size: 14px;
      font-family: var(--main-font-semi-bold);
      transition: all 0.5s;

      button {
        border: none;
        background: none;
        margin: 0;
        padding: 0;
        color: var(--color-light);

        & > svg {
          margin-left: 10px;
          margin-top: -2px;
          transition: transform 0.5s;
        }
        &.showing {
          svg {
            transform: rotate(0deg);
          }
        }
        &.hidding {
          svg {
            transform: rotate(-90deg);
          }
        }
      }
    }

    .StartYear {
      color: var(--color-light);
      position: absolute;
      left: 4px;
      bottom: -1px;
    }
    .EndYear {
      color: var(--color-light);
      position: absolute;
      right: 4px;
      bottom: -1px;
      text-align: right;
    }
  }
}

.poptextwrap {
  white-space: pre-wrap;
  font-size: 12px;
}
