@import '../../styles/variables.scss';

.minimap-container-container {

  .minimap-container {
    width: 100%;
    height: 100%;
  }

  /* Override scales */
  .maplibregl-ctrl-bottom-right, .mapboxgl-ctrl-bottom-right {
    .mapboxgl-ctrl-scale, .maplibregl-ctrl-scale {
      background-color: transparent;
      border: solid 2px white;
      border-top: none;
      margin-bottom: -2px;
      color: white;
      text-align: center;
      font-size: 0.9em;
      height: 15px;
      line-height: 13px;
    }
    .mapboxgl-ctrl-scale ~ .mapboxgl-ctrl-scale, .maplibregl-ctrl-scale ~ .maplibregl-ctrl-scale {
      border: solid 2px white;
      border-bottom: none;
      margin-bottom: 2px;
      padding-top: 1px;
    }
  }

}


