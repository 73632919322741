@import "./responsive.scss";

:root {
  --color-primary: #bfa66a;
  --color-secondary: #d7d7d7;
  --color-accent: #009EE0;
  --color-light: #ffffff;
  --color-backgrey: #666666;
  --color-grey: #bdbdbd;
  --color-lightgrey: #ebebeb;
  --color-dark: rgba(102,102,102, 1);
  --color-lightgold: #dfd3b5;
  --color-darkgold: #8f7d50;
  --color-black: #000;
  --color-burger-modal: #2b2b2b;
  --sidePanelLeftSize: clamp(33vw, 498px, 600px);
  --sidePanelRightSize: clamp(800px, 960px, 66vw);
  --text-color: #000;
  --link-color: #000;
  --main-font: "Inter Regular";
  --main-font-semi-bold: "Inter Semi Bold";
  --main-font-bold: "Inter Bold";
  --main-font-size: 14px;
  --header-font-size: 14px;

  // Override bootstrap variables
  --bs-primary: var(--color-primary);
  --bs-secondary: var(--color-secondray);
}

@include media-min("widescreen") {
  :root {
    --sidePanelLeftSize: clamp(20vw, 498px, 600px);
    --sidePanelRightSize: clamp(960px, 960px, 40vw);
  }
}

/* pop overs */
$popover-bg: var(--color-backgrey);
$popover-header-bg: var(--color-backgrey);
$popover-max-width: 600px;
$popover-font-size: 14px;
$popover-border-radius: 5px;
$popover-body-color: white;
$popover-header-color: white;
$font-family-sans-serif: Inter Regular, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !default;

/* Header */
$headerHeight: 77px;
