@import '../../styles/variables.scss';
@import '../../styles/responsive.scss';

.pagesProject {
  padding: 20px 32px;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  width: 100%;

  .logos {
    margin-top: 40px;
  }
}
