@import '../../styles/variables.scss';
@import '../../styles/responsive.scss';

.MapSideSearch {
  display: flex;
  flex-direction: column;
  height: 100%;

  .SearchForm {
    flex: 1;
    padding: 2em;
    overflow-x: hidden;
    overflow-y: scroll;
    .SearchHeader {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .SaerchHeaderTitle {
        font-size: 22px;
        max-width: 150px;
        font-family: var(--main-font-semi-bold);
        line-height: 27px;
      }
      .SaerchHeaderButtons {
        text-align: right;
        display: none;
        button {
          border: none;
          background: none;
          font-size: 4em;
          color: var(--text-color);
          svg {
            vertical-align: top;
          }
        }
        @include media-max("tablet") {
          display: flex;
        }
      }
    }
    .SlidableTabs {
      margin: 46px 0 0 0;
    }
  }

  .SearchButtons {
    flex: 0 1;
    column-gap: 13px;
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: horizontal;
    padding: 1.5em 2em;
    .SearchResetButton {
      flex: 0 1 auto;
      width: 50px;
      height: 50px;
    }
    .SearchButton {
      flex: 1;
    }
  }

  .SearchButtons:after {
    content: "";
    left: 0;
    right: 0;
    top: -19px;
    height: 19px;
    position: absolute;
    background: linear-gradient(0deg, #0000001A 0%, transparent 100%);
    pointer-events: none;
  }

}
