/* #### Generated By: http://www.cufonfonts.com #### */

    @font-face {
    font-family: 'Inter Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Inter Regular'), url('Inter-Regular.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Inter Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Inter Italic'), url('Inter-Italic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Inter Thin BETA';
    font-style: normal;
    font-weight: normal;
    src: local('Inter Thin BETA'), url('Inter-Thin-BETA.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Inter Thin Italic BETA';
    font-style: normal;
    font-weight: normal;
    src: local('Inter Thin Italic BETA'), url('Inter-ThinItalic-BETA.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Inter Extra Light BETA';
    font-style: normal;
    font-weight: normal;
    src: local('Inter Extra Light BETA'), url('Inter-ExtraLight-BETA.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Inter Extra Light Italic BETA';
    font-style: normal;
    font-weight: normal;
    src: local('Inter Extra Light Italic BETA'), url('Inter-ExtraLightItalic-BETA.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Inter Light BETA';
    font-style: normal;
    font-weight: normal;
    src: local('Inter Light BETA'), url('Inter-Light-BETA.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Inter Light Italic BETA';
    font-style: normal;
    font-weight: normal;
    src: local('Inter Light Italic BETA'), url('Inter-LightItalic-BETA.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Inter Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Inter Medium'), url('Inter-Medium.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Inter Medium Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Inter Medium Italic'), url('Inter-MediumItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Inter Semi Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Inter Semi Bold'), url('Inter-SemiBold.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Inter Semi Bold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Inter Semi Bold Italic'), url('Inter-SemiBoldItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Inter Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Inter Bold'), url('Inter-Bold.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Inter Bold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Inter Bold Italic'), url('Inter-BoldItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Inter Extra Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Inter Extra Bold'), url('Inter-ExtraBold.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Inter Extra Bold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Inter Extra Bold Italic'), url('Inter-ExtraBoldItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Inter Black';
    font-style: normal;
    font-weight: normal;
    src: local('Inter Black'), url('Inter-Black.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Inter Black Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Inter Black Italic'), url('Inter-BlackItalic.woff') format('woff');
    }