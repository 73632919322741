@import "../../styles/variables.scss";

.Legend {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
  padding: 30px;

  li {
    list-style-type: disc;
    margin-bottom: 8px;
  }

  p {
    margin-bottom: 0px;
  }

  .LegendHeader {
    display: flex;
    position: relative;
    height: 80px;
    .LegendHeaderTitle {
      font-family: var(--main-font-semi-bold);
      font-size: 30px;
      flex: 1;
    }
    .LegendContainerRight {
      position: absolute;
      display: flex;
      top: -15px;
      line-height: 22px;
      right: 60px;
      .LegendResultSummary {
        text-align: right;
        font-size: 12px;
      }
      .LegendHeaderButtons {
        margin-left: 18px;
        display: flex;
      }
    }
  }

  /**
   * LegendWhen
  **/
  .LegendWhen {
    div.Periods {
      display: grid;
      div.Period {
        display: grid;
        grid-template-columns:
          1fr minmax(33px, min-content) minmax(min-content, 190px)
          minmax(min-content, 90px) minmax(min-content, 90px);
        align-items: center;
        column-gap: 8px;
        &:hover {
          background-color: var(--color-secondary);
        }
        .PeriodName {
          justify-self: start;
        }
        .PeriodColor {
          align-self: center;
          & > div {
            width: 30px;
            height: 10px;
            border-radius: 3px;
          }
        }
        .PeriodDate {
          display: grid;
          grid-template-columns: 1fr 30px 1fr;
          white-space: nowrap;
          div:first-of-type {
            justify-self: end;
          }
          .arrow {
            padding: 0 8px;
          }
          div:last-of-type {
            justify-self: start;
          }
        }
        .PeriodPactol,
        .PeriodPeriodo {
          text-align: center;
          white-space: nowrap;
          justify-items: center;
        }
      }
    }
  }

  /**
   * LegendWhat
  **/
  .LegendWhat {
    .characs {
      .RootCharac {
        margin-top: 16px;
        .RootCharacName {
          font-family: var(--main-font-bold);
          font-size: 16px;
          padding-bottom: 8px;
          border-bottom: 1px solid black;
          margin-bottom: 12px;
        }
        .SubCharacs {
          display: grid;
          .SubCharac {
            display: grid;
            grid-template-columns: 1fr min-content minmax(
                min-content,
                90px
              );
            justify-items: center;
            align-items: center;
            grid-column-gap: 8px;
            .CharacName {
              justify-self: start;
            }
            .CharacPactol,
            .CharacPeriodo {
              text-align: center;
              white-space: nowrap;
            }
          }
          .SubCharac:hover {
            background-color: var(--color-secondary);
          }
        }
      }
    }
  }

  /**
   * LegendWhere
  **/
  .LegendWhere {
    .Coords {
      .Coord {
        display: grid;
        column-gap: 8px;
      }
    }
  }

  /**
   * LegendOther
  **/
  .LegendOther {
    .Others {
      .Other {
        display: grid;
      }
    }
  }

  /**
   * LegendCitations
  **/
  .LegendCitations {
    border-color: 1px solid red;
  }
}

.BoundingBox {
  position: relative;
  margin-top: 12px;
  // background-color: var(--color-secondary);
  padding: 10px;
  .Area {
    width: 100%;
    height: 100%;
    background-color: var(--color-lightgrey);
    position: relative;
    color: #333;
    font-size: 12px;
    .NE {
      position: absolute;
      right: 4px;
      top: 2px;
    }
    .SW {
      position: absolute;
      bottom: 2px;
      left: 6px;
    }
    .Corner {
      width: 20px;
      height: 20px;
      border: 0px solid var(--color-primary);
      position: absolute;
      &.TopLeft {
        top: -8px;
        left: -8px;
        border-top-width: 4px;
        border-left-width: 4px;
      }
      &.TopRight {
        top: -8px;
        right: -8px;
        border-top-width: 4px;
        border-right-width: 4px;
      }
      &.BottomLeft {
        bottom: -8px;
        left: -8px;
        border-bottom-width: 4px;
        border-left-width: 4px;
      }
      &.BottomRight {
        bottom: -8px;
        right: -8px;
        border-bottom-width: 4px;
        border-right-width: 4px;
      }
    }
  }
}
