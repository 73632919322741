.FlatSelect {
  flex: 1;
  flex-direction: column;
  &.disabled {
    opacity: 0.5;
  }

  & > .FlatSelectSelect {
    display: flex;
    flex: 1;
    flex-direction: row;
    & > .label {
      flex-grow: 1;
      display: flex;
      border-radius: 5px;
      text-align: left;
      position: relative;
    }
    &.show-radio > .label {
      padding-right: 56px;
    }

    & > .leaf {
      .label {
        .radio {
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          background-image: url(../../../public/icons/radio-unselected.svg);
          background-repeat: no-repeat;
          background-position: center center;
          width: 56px;
          align-self: center;
          &.selected {
            background-image: url(../../../public/icons/radio-selected.svg);
          }
        }
      }
    }

    &.notleaf > .label {
      border-radius: 5px 0 0 5px;
      margin-right: 1px;
    }

    & > .icon {
      display: flex;
      flex: 0;
      align-items: center;
      border-radius: 0 5px 5px 0;
      margin-left: 1px;

      & > .opened {
        transform: rotate(0deg);
        transition-duration: 0.5s;
      }
      & .closed {
        transform: rotate(-90deg);
        transition-duration: 0.5s;
      }
    }
  }

  & > .FlatSelectContent {
    flex: 1;
    flex-direction: column;
    margin-left: 1em;
  }
}
