@import "../../styles/variables.scss";
@import "../../styles/responsive.scss";

.burgerModal {
  position: fixed;
  top: -100dvh;
  left: 0;
  width: 100dvw;
  height: 100dvh;
  display: grid;
  column-gap: 140px;
  font-family: var(--main-font-semi-bold);
  background-color: var(--color-burger-modal);
  grid-template-areas: "zone1 zone2";
  z-index: 1000;
  padding: 30px;
  transition: transform 0.3s;
  &.show {
    transform: translateY(100dvh);
  }

  @include media-max("tablet") {
    grid-template-areas: "zone1" "zone2" "zone3";
    grid-template-rows: min-content min-content min-content;
    row-gap: 32px;
    padding-top: 105px;
  }

  img {
    position: absolute;
    top: 21px;
    left: 31px;
    height: 35px;
  }

  .closeButton {
    position: absolute;
    top: 12px;
    right: 2px;
    padding: 10px 24px;
    font-size: 40px;
    border: none;
    background: none;
    color: var(--color-light);
    transition: color 0.2s;
    &:hover {
      color: var(--color-primary);
    }
  }

  a {
    font-family: var(--main-font-semi-bold);
    color: var(--color-primary);
    text-decoration: none;
    font-size: 40px;
    line-height: 48px;
    border: none;
    border-bottom: 1px solid transparent;
    width: min-content;
    @include media-max("tablet") {
      font-size: 28px;
      line-height: 33px;
    }
    &.smallLink {
      font-size: 20px;
      line-height: 24px;
      @include media-max("tablet") {
        font-size: 18px;
        line-height: 22px;
      }
    }
    &:hover {
      color: var(--color-darkgold);
      border-bottom: 1px dotted var(--color-primary);
    }
  }

  .linkGroup1 {
    // background-color: pink;
    grid-area: zone1;
    display: flex;
    flex-direction: column;
    white-space: nowrap;
    width: min-content;
    min-height: 240px;
    justify-self: flex-end;
    align-self: center;
    justify-content: space-between;
    @include media-max("tablet") {
      justify-self: flex-start;
      row-gap: 32px; // Same as main grid row-gap
      min-height: auto;
    }
  }

  .linkGroup2 {
    // background-color: lemonchiffon;
    grid-area: zone2;
    display: flex;
    flex-direction: column;
    width: min-content;
    min-height: 240px;
    white-space: nowrap;
    align-self: center;
    justify-content: space-between;
    @include media-max("tablet") {
      min-height: auto;
      row-gap: 28px;
      justify-self: flex-start;
    }
    & > div {
      display: grid;
      row-gap: 23px;
      @include media-max("tablet") {
        grid-template-columns: 1fr 1fr;
        column-gap: 110px;
      }
    }
  }

  .linkGroup3 {
    // background-color: lightblue;
    grid-area: zone3;
    position: absolute;
    display: flex;
    justify-content: center;
    left: 0;
    bottom: 69px;
    width: 100%;
    white-space: nowrap;
    column-gap: 112px;
    @include media-max("tablet") {
      &::before {
        content: '';
        width: 100%;
        border-bottom: 1px solid #444;
      }
      position: relative;
      bottom: 0;
      flex-direction: column;
      row-gap: 28px;
    }
    .lang {
      position: absolute;
      top: -7px;
      right: 50px;
      width: 80px;
      @include media-max("tablet") {
        position: relative;
        left: 250px;
        top: -55px;
      }
      & > select {
        cursor: pointer;
        color: var(--color-primary);
        font-size: 20px;
        background-position: right 18px center;
        @include media-max("tablet") {
          font-size: 18px;
          line-height: 22px;
        }
      }
    }
  }

}
