@import "../../styles/variables.scss";

$width: 221px;
$height: 395px;

.staticLegend {
  position: absolute;
  display: grid;
  grid-template-columns: 12px 1fr;
  grid-auto-columns: 100%;
  column-gap: 12px;
  font-size: 12px;
  left: calc(-#{$width} - 2px);
  bottom: 1px;
  background-color: var(--color-dark);
  color: var(--color-light);
  width: $width;
  height: $height;
  border-radius: 4px;
  text-align: left;
  padding: 16px 18px 25px 20px;
  align-items: center;
  cursor: default;
  .close {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 20px;
    right: 20px;
    width: 14px;
    height: 14px;
    cursor: pointer;
  }
  .label {
    grid-column: 1 / 3;
    width: 100%;
    font-weight: 700;
    line-height: 18px;
    padding-left: 1px;
    margin-bottom: -1px;
  }
  .spacer {
    height: 16px;
    grid-column: 1 / 3;
  }
  .exceptional {
    font-weight: 700;
  }
}

.droplet {
  justify-self: center;
  path {
    fill: var(--color-primary);
  }
}

.circle {
  border-radius: 50%;
  background-color: var(--color-primary);
  justify-self: center;
}

.star {
  justify-self: center;
  path {
    fill: var(--color-primary);
  }
}

.datation {
  justify-self: center;
  width: 13px;
  height: 8px;
  border-radius: 2px;
  background-color: var(--color-light);
}

.button {
  margin-top: 1px;
  border-radius: 2px;
  &.active {
    background-color: var(--color-primary) !important;
  }
}
