@import '../../styles/variables.scss';

$chronology_height: 6em;

.ChronologySelector {
  display: flex;
  position: relative;

  button[class~="swiper-prev-button"],
  button[class~="swiper-next-button"] {
    border: none;
    background: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
  }

  button[class~="swiper-prev-button"] {
    left: -23px;
  }

  button[class~="swiper-next-button"] {
    right: -23px;
  }

  swiper-slide {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
  }

  .Chronology {
    display: flex;
    align-items: center;
    height: $chronology_height;
    border-radius: 0.4em;
    padding: 3.3em 0.7em;
    cursor: pointer;
    border: none;
    width: 100%;
    text-align: left;

    .Icon {
      flex: 0 97px;
      height: 72px;
      border: solid 1px black;
      border-radius: 0.5em;
      background-position: center;
      background-repeat: no-repeat;    }

    .Name {
      flex: 1;
      font-family: var(--main-font-bold);
      font-size: 14px;
      margin-left: 1em;
    }
  }

  .Chronology.selected {
    .Icon {
      background-color: white;
    }
  }

}
